import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';

import { ShoppingCartService } from '@offconon/core-api';
import { TruncatePipe } from '@offconon/shared/utils/pipes';
import { EnvironmentService } from '@offconon/shared/utils/services';
import { ShoppingCartService as CartHelperService } from '@offconon/shared/utils/services';
import { platform, PlatformService } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-shopping-cart-checkout-ad-item-common',
  templateUrl: './shopping-cart-checkout-ad-item-common.component.html',
  imports: [
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule,
    CurrencyPipe,
    DatePipe,
    TruncatePipe,
    TranslateModule,
  ],
})
export class ShoppingCartCheckoutAdItemCommonComponent implements OnInit {
  private router = inject(Router);
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private messageService = inject(MessageService);

  private environmentService = inject(EnvironmentService);
  @Input() system_data: any;
  @Input() ad_system_data: any;
  @Input() open_link = /ad-details/;
  @Output() formSavedEvent = new EventEmitter<any>();

  market: any;
  platform: platform;

  ad_url_path: any;

  form: FormGroup;
  private destroyRef = inject(DestroyRef);
  loading = false;
  private shoppingCartService = inject(ShoppingCartService);
  private cartHelperService = inject(CartHelperService);
  private platformService = inject(PlatformService);

  ngOnInit() {
    this.platformService.platformObservable.subscribe({
      next: (platform) => {
        this.platform = platform;
      },
    });

    switch (this.ad_system_data?.service_type) {
      case 'ad_digital_product': {
        this.market = 'digital-product';
        break;
      }
      case 'ad_physical_product': {
        this.market = 'physical-product';
        break;
      }
      case 'ad_rent': {
        this.market = 'rent';
        break;
      }
      case 'ad_digital_media': {
        this.market = 'digital-media';
        break;
      }
      case 'ad_booking': {
        this.market = 'booking';
        break;
      }
      case 'ad_job': {
        this.market = 'job';
        break;
      }
      case 'ad_food_beverage': {
        this.market = 'food-beverage';
        break;
      }
      case 'ad_service_offered': {
        this.market = 'service';
        break;
      }
    }

    this.form = this.fb.group({
      id: [this.ad_system_data?.shopping_cart_item_id],
      quantity: [this.ad_system_data?.quantity, [Validators.required]],
    });
  }
  OpenAd() {
    this.ad_url_path =
      this.open_link +
      this.market +
      '/' +
      this.ad_system_data.service_id +
      '/' +
      this.ad_system_data?.slug;

    if (this.platform === 'erp') {
      window.open(`${this.environmentService.environment.coreUrl}` + this.ad_url_path, '_blank');
    } else {
      this.router.navigate([this.ad_url_path]);
    }
  }

  submit() {
    this.loading = true;
    let payload: any = {};
    payload = this.form.value;
    this.shoppingCartService
      .shoppingCartUpdatePartialUpdate(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          this.formSavedEvent.emit(result);
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('Successful'),
            detail: this.translateService.instant(
              'You have successfully modified your shopping cart!',
            ),
          });
        },

        complete: () => {
          this.loading = false;
        },
      });
  }

  deleteItem() {
    this.cartHelperService.setShoppinCartDeleteItemData({
      shopping_cart_item_id: this.ad_system_data.shopping_cart_item_id,
      id: this.system_data.id,
    });
  }
}
