<div class="flex-auto overflow-y-auto">
  <div class="flex items-start sm:items-center mb-4">
    <img
      class="object-cover rounded-lg imageresponsive w-28 h-28 flex-shrink-0 cursor-pointer"
      src="{{
        ad_system_data?.cover_path ? ad_system_data.cover_path : '/assets/images/default_cover.png'
      }}"
      alt=" {{ ad_system_data?.ad_name }}"
      (click)="OpenAd()" />

    <div class="flex-auto px-3 mt-2">
      <div class="flex items-center justify-between mb-1">
        <span class="text-blue-500 text-sm cursor-pointer leading-6" (click)="OpenAd()">
          {{ ad_system_data?.ad_name | truncate: 20 }}</span
        >
        <span class="text-sm">
          {{
            ad_system_data?.price?.price * 1 * ad_system_data?.quantity
              | currency: ad_system_data?.price?.currency
          }}</span
        >
      </div>
      <div class="text-xs mb-2">
        @if (ad_system_data.service_type === 'ad_service_offered') {
          <span class="italic mr-1">{{ 'Booked appointment' | translate }}: </span>
          <span class="font-bold">
            {{ ad_system_data?.appointment?.date | date: 'short' }}
            <!-- <offconon-date-pipe-locale-render
              [date_data]="'2024-12-21 23:05:30'"
              [dateformat]="'short'" -->
            /></span
          >
          <div class="flex flex-auto justify-between items-center">
            <button pButton icon="pi pi-file-edit" class="p-button-text p-button-rounded"></button>
          </div>
        }
      </div>

      @if (
        ad_system_data?.quantity &&
        (ad_system_data.service_type === 'ad_physical_product' ||
          ad_system_data.service_type === 'ad_digital_product')
      ) {
        <span class="italic text-xs mb-1">{{ 'Quantity' | translate }}: </span>
        <div class="flex flex-auto justify-between items-center">
          <form action="" class="" [formGroup]="form">
            <p-inputNumber
              formControlName="quantity"
              buttonLayout="horizontal"
              inputId="horizontal"
              spinnerMode="horizontal"
              decrementButtonClass="p-button-text p-button-danger py-1 px-1"
              incrementButtonClass="p-button-text p-button-success py-1 px-1"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              [inputStyleClass]="
                'w-4 md:w-12 text-center py-2 px-1 border-transparent text-xs text-blue-500'
              "
              [style]="{ width: '100%' }"
              [styleClass]="'border-none '"
              [showButtons]="true"
              [step]="1"
              [min]="1"
              [max]="9000000000000"
              (onInput)="submit()" />
          </form>
        </div>
      }

      <div class="flex flex-row-reverse flex-wrap">
        <div class="flex items-center justify-center">
          <button
            pButton
            icon="pi pi-trash"
            class="p-button-danger p-button-text p-button-rounded"
            (click)="deleteItem()"></button>
        </div>
      </div>
    </div>
  </div>
</div>
